export default class UserInfo {
    static userDetail = JSON.parse(sessionStorage.getItem("userDetail") || "{}")
    static userinfo

    static getCommunityCode() {
        return this.userDetail?.communityCode
    }

    static get(key) {
        return parent.vue.loginInfo.userinfo[key]
    }
}
