import $core from "@/utils/core"
import { get } from "@/api/index"

export default class Upload {
    static async uploadFile(file) {
        var bucket = await this.getBucket(file)

        const formdata = new FormData()

        formdata.append("name", bucket.name)
        formdata.append("host", bucket.host)
        formdata.append("key", bucket.key)
        formdata.append("policy", bucket.policy)
        formdata.append("OSSAccessKeyId", bucket.OSSAccessKeyId)
        formdata.append("success_action_status", bucket.success_action_status)
        formdata.append("signature", bucket.signature)
        formdata.append("file", file)

        // 上传文件
        await this.__uploadFile(bucket.host, formdata)
        // 返回文件地址
        return bucket.host + bucket.key
    }

    static getBucket(file) {
        return new Promise((resolve, reject) => {
            get("/old/api/common/file/getWebPolicy?bucketName=liefeng")
                .then(res => {
                    if (res.code == 200) {
                        const data = res.data
                        let now = $core.randomString()
                        //防止文件名有.
                        let suffixArr = file.name.split(".")
                        let suffix = "." + suffixArr[suffixArr.length - 1]

                        resolve({
                            name: now + suffix,
                            host: data.host,
                            key: data.key + now + suffix,
                            policy: data.encodedPolicy,
                            OSSAccessKeyId: data.accessId,
                            success_action_status: "200",
                            signature: data.postSignature,
                            url: data.host + data.key + now + suffix,
                        })
                    } else {
                        reject(res.desc)
                    }
                })
                .catch(reject)
        })
    }

    /**
     * 上传文件
     */
    static __uploadFile(url, formdata) {
        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest()

            request.open("POST", url)

            request.onload = () => {
                if (request.status === 200) {
                    resolve()
                } else {
                    reject(request.responseText)
                }
            }

            request.onerror = reject

            request.send(formdata)
        })
    }
}
